



import { Vue, Component, Watch } from "vue-property-decorator";
import DocumentEditView from "./molecles/DocumentEditView.vue";
import { Document } from "@/models/documents/Document";
import { container } from "tsyringe";
import { DocumentEditService } from "@/models/documents/DocumentEditService";

/**
 * 資料設定ページを提供します.
 */
@Component({ components: { DocumentEditView } })
export default class DocumentEditPage extends Vue {
    /**
     * 資料サービス
     */
    private readonly documentEditService = container.resolve(DocumentEditService);

    /**
     * 編集する資料
     */
    private document = new Document();

    /**
     * コンポーネント生成時に実行されます．
     */
    private async created(): Promise<void> {
        const id = this.$route.params.id;
        this.documentEditService.document = null;
        const doc = await this.documentEditService.fetch(id);
        if (doc) {
            this.document = doc;
        }
    }

    private onBackReqested() {
        this.$router.push("/document/" + this.$route.params.dgId);
    }
}
