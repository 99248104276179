














































































































































































































import { Vue, Inject, Component, Watch, Emit, Prop } from "vue-property-decorator";
import { ContextMenu, ConfirmDialog, ConfirmDialogContent, FileDropAreaCompact } from "ui-gallery";
import { animate } from "@/models/utilities/animate";
import { Document } from "@/models/documents/Document";
import { EditableDocumentPage } from "@/models/documents/EditableDocumentPage";
import { DocumentPage } from "../../../../models/documents/DocumentPage";
import { DocumentEditService } from "@/models/documents/DocumentEditService";
import { container } from "tsyringe";
import { AuthService } from "@/models/auth/AuthService";
import { config } from "@/config";

/**
 * 資料設定ページを提供します.
 */
@Component({ components: { ContextMenu, ConfirmDialog, FileDropAreaCompact } })
export default class DocumentEditView extends Vue {
    private selectedIndex = 0;
    private isLoading = false;
    private isUploading = false;
    private menuContextDocsIndex = -1;
    private config = config;

    @Emit("backRequested")
    private backRequested() { }

    private readonly authService = container.resolve(AuthService);

    /**
     * 資料サービス
     */
    private readonly documentEditService = container.resolve(DocumentEditService);

    /**
     * メッセージを通知します．
     */
    @Inject()
    private notify!: (message: string, timeout?: number, color?: string) => Promise<void>;


    private get token() {
        return this.authService.token;
    }

    private get selected() {
        if (this.documentEditService.document) return this.documentEditService.document.pages[this.selectedIndex];
        return null;
    }

    private get menuContextDocs() {
        if (this.documentEditService.document) return this.documentEditService.document.pages[this.menuContextDocsIndex];
        return null;
    }

    private async back(): Promise<void> {
        this.backRequested();
    }

    private onShowContextMenu(index: number, e: MouseEvent) {
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (!menu) return;
        this.menuContextDocsIndex = index;
        menu.show(e.x, e.y);
    }

    private onRemovePage() {
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (!menu || !this.documentEditService.document) return;
        menu.close();

        this.withLoading(async () => {
            if (!this.documentEditService.document || !this.menuContextDocs) return;
            await this.documentEditService.removePage(this.documentEditService.document.docId, this.menuContextDocs.page.xdpId);
        });
        this.documentEditService.document.pages.splice(this.menuContextDocsIndex, 1);
    }

    private addPageToBottom() {
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (!menu) return;
        menu.close();

        if (!this.menuContextDocs) return;
        const item = new EditableDocumentPage(new DocumentPage());
        this.menuContextDocsIndex++;
        this.selectedIndex = this.menuContextDocsIndex;
        item.index = this.menuContextDocsIndex;
        if (this.documentEditService.document) this.documentEditService.document.pages.splice(this.menuContextDocsIndex, 0, item);
    }

    private addPageToTop() {
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (!menu || !this.documentEditService.document) return;
        menu.close();

        if (!this.menuContextDocs) return;
        const item = new EditableDocumentPage(new DocumentPage());
        this.selectedIndex = this.menuContextDocsIndex;
        item.index = this.menuContextDocsIndex;
        this.documentEditService.document.pages.splice(this.menuContextDocsIndex, 0, item);
    }

    private addPageTop() {
        const menu2 = this.$refs.menu2 as ContextMenu | undefined;
        if (!menu2 || !this.documentEditService.document) return;
        menu2.close();
        const item = new EditableDocumentPage(new DocumentPage());
        this.selectedIndex = 0;
        item.index = 0;
        this.documentEditService.document.pages.splice(0, 0, item);
    }

    private addPageBottom() {
        const menu2 = this.$refs.menu2 as ContextMenu | undefined;
        if (!menu2) return;
        menu2.close();
        if (!this.documentEditService.document) return;

        const item = new EditableDocumentPage(new DocumentPage());
        this.selectedIndex = this.documentEditService.document.pages.length - 1;
        item.index = this.documentEditService.document.pages.length - 1;
        this.documentEditService.document.pages.splice(this.documentEditService.document.pages.length, 0, item);
    }

    @Watch("document")
    private onDocumentChanged() {
        if (!this.documentEditService.document) return;
        if (this.documentEditService.document.pages.length) {
            this.selectedIndex = 0;
        }
    }

    private async dropped(file: File): Promise<void> {
        this.withLoading(async () => {
            if (!this.documentEditService.document) return;
            const result = await this.documentEditService.createNewPage(this.documentEditService.document.docId, this.selectedIndex, file);
            if (!result) this.notify("ファイルのアップロードに失敗しました", 3000, "error");
            else this.documentEditService.fetch(this.documentEditService.document.docId);
        });
    }

    /**
     * コンポーネントが作成されたきに実行されます．
     */
    private created() {
    }

    private async onDocumentSelected(page: number): Promise<void> {
        const pageViewer = this.$refs.pageViewer as HTMLElement;
        await animate(pageViewer, "opacity", "0", 0.3);
        this.selectedIndex = page;
        await animate(pageViewer, "opacity", "1", 0.3);
    }

    private beforeDestroy() {
    }

    private onListClicked(e: MouseEvent) {
        const menu2 = this.$refs.menu2 as ContextMenu | undefined;
        if (!menu2) return;
        const menu = this.$refs.menu as ContextMenu | undefined;
        if (!menu) return;
        menu2.show(e.x, e.y);
    }

    private async onUpdated() {
        if (this.selected && this.documentEditService.document) {
            this.notify("保存しています");
            this.isUploading = true;
            await this.documentEditService.savePage(this.documentEditService.document.docId, this.selected);
            this.notify("保存しました");
            this.isUploading = false;
        }
    }

    private withLoading<T>(task: () => Promise<T>): Promise<T> {
        return new Promise<T>(async resolve => {
            this.isLoading = true;
            const res = await task();
            this.isLoading = false;
            resolve(res);
        });
    }
}
