import { IDocumentPage } from "./IDocumentPage";

/**
 * 資料を表します．
 */
export class DocumentPage implements IDocumentPage {
    xdpId = "";
    order = 0;
    url = "";
    thumbUrl = "";
    remarks = "";
    mimeType = "";
    /**
     * コンストラクタ
     * @param page 初期値
     */
    public constructor(page?: Partial<IDocumentPage>) {
        Object.assign(this, page);
    }
}
